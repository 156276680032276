import React from 'react'
import SettingsComp from '../compements/Settings.js'
function Settings() {
  return (
    <>
      {/* <ProfileImageUpdater/> */}
      <SettingsComp/>
    </>
  )
}

export default Settings
