// src/components/SignUp.js
import React, { useRef, useEffect, useState } from 'react';
import { Formio } from 'formiojs';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from "../media/Logfav-White.svg";
import api from '../api'; // your axios instance

const signUpFormSchema = {
  display: 'form',
  components: [
    {
      type: 'email',
      key: 'email',
      label: 'Email',
      placeholder: 'Enter your email address',
      input: true,
      validate: { required: true }
    },
    {
      type: 'textfield',
      key: 'couponCode',
      label: 'Coupon Code',
      placeholder: 'Enter coupon code (optional)',
      input: true,
      validate: { required: false }
    },
    {
      type: 'password',
      key: 'password',
      label: 'Password',
      placeholder: 'Enter your password',
      input: true,
      validate: { 
        required: true,
        minLength: 6 
      }
    },
    {
      type: 'password',
      key: 'confirmPassword',
      label: 'Confirm Password',
      placeholder: 'Confirm your password',
      input: true,
      validate: {
        required: true,
        custom: "valid = (input === data.password);",
        customMessage: "Password does not match"
      },
      persistent: false
    },
    {
      type: "checkbox",
      key: "termsAccepted",
      label: "I acknowledge and agree that I am solely responsible for reviewing and verifying the accuracy, legality, and compliance of any marketing copy generated by this chatbot. I understand that the chatbot provides content suggestions based on user input and does not guarantee compliance with legal, regulatory, or industry standards. I accept full legal responsibility for the use of any content generated.",
      input: true,
      validate: {
        required: true,
        customMessage: "Terms of conditions are required"
      },
      persistent: false
    },
    {
      type: 'button',
      action: 'submit',
      label: 'Sign Up',
      theme: 'primary',
      input: false
    }
  ]
};

const SignUp = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    if (formRef.current) {
      Formio.createForm(formRef.current, signUpFormSchema)
        .then((form) => {
          form.on('submit', async (submission) => {
            try {
              const dataToSubmit = { ...submission.data };

              // Remove fields not needed during registration
              delete dataToSubmit.confirmPassword;
              delete dataToSubmit.termsAccepted;

              // Ensure username is set (Strapi requires a username or email)
              if (!dataToSubmit.username) {
                dataToSubmit.username = dataToSubmit.email;
              }

              // Save the coupon code separately (if any)
              const coupon = dataToSubmit.couponCode;
              // We won't send couponCode in the register call
              delete dataToSubmit.couponCode;

              // 1) REGISTER THE USER (no trialEnd yet)
              const registerResponse = await api.post('/api/auth/local/register', dataToSubmit);
              console.log('Registration successful', registerResponse.data);

              // Store JWT and user data if needed
              const token = "e7ec04e1cde649743676246e335cd583e97b923accf9ea79a176bdc072c1a3f321e957127b11c45a53ed567d98af0c414b50af4f05752305432d74ba4ff1c3692f0327a886fdd561e1f4432b811bdb0188c18fc51577e5d7b13cae3a518f2745ab208ebc80db7e330cab5fe09bca8141fce0b2333a1322692abb400b18a78dbe";
              const newUser = registerResponse.data.user;
              localStorage.setItem('jwt', token);

              // 2) IF COUPON IS "MONEYMONDAY", UPDATE trialEnd VIA A SECOND REQUEST
              if (coupon && coupon.toLowerCase() === 'moneymonday') {
                // Calculate date 8 days from now
                const trialEndDate = new Date();
                trialEndDate.setDate(trialEndDate.getDate() + 8);

                // If your Strapi user model has "Date" type, use YYYY-MM-DD:
                const yyyy = trialEndDate.getFullYear();
                const mm = String(trialEndDate.getMonth() + 1).padStart(2, '0');
                const dd = String(trialEndDate.getDate()).padStart(2, '0');
                const formattedTrialEnd = `${yyyy}-${mm}-${dd}`;

                // Example PUT request to update the user
                // NOTE: In Strapi v4, the default route might be `/users/${id}`, not `/api/users/${id}`.
                await api.put(
                  `/api/users/${newUser.id}`,
                  { trialEnd: formattedTrialEnd },
                  { headers: { Authorization: `Bearer ${token}` } }
                );
              }

              // 3) SUCCESS MESSAGE
              toast.success('Registration successful!');

              // 4) REDIRECT:
              //    - If coupon is "MONEYMONDAY", go to sign-in
              //    - Otherwise, go to Stripe checkout
              if (coupon && coupon.toLowerCase() === 'moneymonday') {
                navigate('/signin');
              } else {
                const redirectUrl = `https://buy.stripe.com/6oEdTP5i41SR1he6oB?prefilled_email=${encodeURIComponent(dataToSubmit.email)}`;
                window.location.href = redirectUrl;
              }
            } catch (err) {
              console.error(err);
              const errorMessage =
                err.response?.data?.error?.message || 'Registration failed. Please try again.';
              setError(errorMessage);
              toast.error(errorMessage);
            }
          });
        })
        .catch((err) => {
          console.error('Error creating form:', err.message);
          toast.error('Error creating registration form.');
        });
    }
  }, [formRef, navigate]);

  return (
    <div className='bg-signIn bg-cover max-w-full mx-auto min-h-[100vh]'>
      <div className='max-w-[750px] w-full sing-up-in mx-auto py-[50px] px-[20px]'>
        <img
          src={logo}
          alt="Profile"
          className="w-full h-auto mx-auto mb-[50px] max-w-[200px]"
        />
        <h1 className='text-[35px] font-[700] text-center mb-[30px] text-white'>Sign Up</h1>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div ref={formRef} className="space-y-4"></div>
        <p className='text-center text-white'>
          Already have an account? <Link to="/signin">Sign In</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
