// src/components/Content.js
import React, { useState, useEffect } from 'react';
import Tiles from '../compements/Tiles.js'; // Ensure the path is correct.
import '../styles/Results.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Content() {
  const [savedData, setSavedData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the string from local storage.
    const formData = localStorage.getItem('formData');

    // Check if formData is missing or its length is less than 10.
    if (!formData || formData.length < 10) {
      toast.error('Please fill out the form first.');
      navigate('/form');
    } else {
      try {
        // Parse the data.
        const parsedData = JSON.parse(formData);
        setSavedData(parsedData);
      } catch (error) {
        console.error('Error parsing formData:', error);
        toast.error('There was an error processing your form data. Please try again.');
        navigate('/form');
      }
    }
  }, [navigate]);

  // Optionally, display a loading indicator while checking localStorage.
  if (savedData === null) {
    return null;
  }

  return (
    <div
      className="max-w-[1200px] mx-auto flex flex-col items-center"
      style={{ height: 'fit-content' }}
    >
      <h2 className='text-[28px] md:text-[35px] font-[700] text-center mb-[10px] text-black'>Your Brand Bot is Ready!</h2>
      <h3 className='text-[18px] md:text-[16px] font-[500] text-center mb-[30px] text-black'>Based on your answers, we’ve prepped everything you need to dominate social media! Now, choose the content you want to generate—whether it’s video scripts, viral headlines, or engaging social posts.</h3>
      <Tiles data={savedData} />
      <button
        onClick={() => navigate('/form')}
        className="h-full mt-10 text-[25px] px-[30px] py-[10px] bg-[#EDEDF4] text-black rounded-full hover:bg-opacity-90 font-bold"
      >
        {`< Change Brand Data`}
      </button>
    </div>
  );
}

export default Content;
