import React from 'react';
import Tile from './Tile';
import CarouselImg from '../media/Tiles/CarouselImg.svg';
import ProductPromotion from '../media/Tiles/ProductPromotion.svg';
import FactsImg from '../media/Tiles/FactsImg.svg';
import HeadlinesIMG from '../media/Tiles/HeadlinesIMG.svg';
import MythBusterImg from '../media/Tiles/MythBusterImg.svg';
import PromotionalImg from '../media/Tiles/PromotionalImg.svg';
import QuotesImg from '../media/Tiles/QuotesImg.svg';
import Stats from '../media/Tiles/Stats.svg';
import VideoImg from "../media/Tiles/VideoImg.svg"
import Checkout from '../media/Tiles/checkout.png';
import Facebook from '../media/Tiles/facebookads.png';
import Google from '../media/Tiles/google.png';
import Instagram from '../media/Tiles/instagram.png';
import landing from '../media/Tiles/landingpage.png';
import PDF from '../media/Tiles/pdf.png';
import linkedin from '../media/Tiles/linkedin.png';
import Brainstroming from '../media/Tiles/offerbrainstroming.png';
import Emails from '../media/Tiles/email.png';
import Upsell from '../media/Tiles/upsell.png';
import Youtube from '../media/Tiles/youtuve.png';
function Tiles() {
    const getStoredData = () => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
          return JSON.parse(savedData); // Parse the string back into an object
        }
        return null; // Return null if no data is found
      };
      const props = getStoredData();
  const promptPostStructure = {
  "My brand name, and niche I operate in are": `${props.brandNameNiche}`,
  "My target audience, their demographics, pain points, and key frustrations are": `${props.targetAudience}`,
  "My core values and unique selling points (USPs) are": `${props.keyBrandValuesUSP}`,
  "My website for CTAs is": `${props.website}`,
  "tone or voice that the content should reflect is": `${props.toneVoiceContent}`,
  "examples of my best-performing posts are.": `${props.brandBioElevatorPitch}`,
  "My main Teaching topics are": `${props.mainTeachingTopics}`,
  "My desired length for written content is": `${props.contentLength}`,
  "post topics for content ideas that resonate with my audience are": `${props.corePostIdeas}`
  }
    const content = {
      
        tile01: {
            color: "linear-gradient(133deg, #C38055 -13.36%, #D19977 99.5%)",
            heading: "CHECKOUT PAGE",
            content: "A seamless checkout that boosts conversions.",
            button: "Optimize your checkout!",
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "checkout",
            popup: true,
            image: `${Checkout}`,
        },
        tile02: {
            color: "linear-gradient(133deg, #226FC7 -13.36%, #5A9DE3 99.5%)",
            heading: "FACEBOOK ADS",
            content: "Stop the scroll. Get the click.",
            button: "Find my ads here!",
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "facebook",
            popup: true,
            image: `${Facebook}`,
          },
        tile03: {
            color: "linear-gradient(133deg, #DC9B37 -13.36%, #F0B668 99.5%)",
            heading: "GOOGLE ADS",
            content: "Ads that get clicks and convert fast.",
            button: "Grab my ad copy",
            // prompt: `Google Ads Content: Develop 2 Google Ads copy variations, each with a short, attention-grabbing headline and a concise description. Focus on key benefits, optimized for search intent, with a clear call-to-action. Use the brand data provided in the JSON to ensure relevance.\nCompliance: Follow FTC and Google Ads policies, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "google",
            popup: true,
            image: `${Google}`,
        },
        tile04: {
            color: "linear-gradient(133deg, #6124C7 -13.36%, #D4A370 99.5%)",
            heading: "IG REEL SCRIPTS",
            content: "Reels that stop the scroll and boost engagement.",
            button: "Steal my Reel scripts!",
            // prompt: `"Instagram Reel Scripts: Generate a short, energetic script for an Instagram Reel video (30-60 seconds). The script should include dynamic, engaging language with clear cues for visuals or transitions. Incorporate a compelling call-to-action and reflect the brand tone using data provided in the JSON.\nCompliance: Follow FTC and Instagram content policies, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "instagram",
            popup: true,
            image: `${Instagram}`,
        },
        tile05: {
            color: "linear-gradient(133deg, #828C6F -13.36%, #A2A994 99.5%)",
            heading: "LANDING PAGE COPY",
            content: "Copy that turns visitors into buyers.",
            button: "See my landing pages!",
            // prompt: `Landing Page Copy: Develop a comprehensive, long-form sales page that includes: A strong headline and subheadline. An engaging introduction. Sections highlighting key benefits, features, and differentiators. Emotional triggers addressing pain points. Trust signals (testimonials, guarantees, security badges). FAQs to overcome objections. Strong call-to-action (CTA) buttons throughout. Ensure the tone aligns with the brand data provided in the JSON.\nCompliance: Follow FTC guidelines and landing page best practices, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "landingpage",
            popup: true,
            image: `${landing}`,
        },
        tile06: {
            color: "linear-gradient(133deg, #BC4752 -13.36%, #C6747C 99.5%)",
            heading: "LEAD MAGNET PDF",
            content: "PDFs that educate and generate leads.",
            button: "Download my PDF template!",
            // prompt: `Lead Magnet PDF: Create an outline and detailed content for a lead magnet PDF (e-book, guide, or report) that provides actionable insights. Include an engaging title, introduction, multiple sections with clear headings, and a call-to-action. Integrate brand data from the JSON.\nCompliance: Follow FTC guidelines, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "pdf",
            popup: true,
            image: `${PDF}`,
        },
        tile07: {
            color: "linear-gradient(133deg, #3B89BF -13.36%, #62A4CD 99.5%)",
            heading: "LINKEDIN POSTS",
            content: "Posts that position you as the expert.",
            button: "Follow for insights",
            // prompt: `LinkedIn Posts: Generate 2 professional LinkedIn posts. One post should be educational and value-driven, providing actionable tips or industry insights without being overly promotional. The second post can be promotional, highlighting a product or service with a clear call-to-action. Ensure the messaging is authentic and aligns with the brand's voice. Compliance: Follow FTC and LinkedIn content policies, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "linkedin",
            popup: true,
            image: `${linkedin}`,
        },
        tile08: {
            color: "linear-gradient(133deg, #D5AF43 -13.36%, #E0BB67 99.5%)",
            heading: "OFFER BRAINSTORMING",
            content: "Ideas that turn into profitable offers",
            button: "Steal my offer ideas",
            // prompt: `Offer Brainstorming: Generate 5 creative ideas for new products or services that align with the brand's niche. Each idea should include: A catchy name. A brief description. Key customer benefits. How it fits within the brand's current offerings. Ensure relevance using brand data from the JSON.\nCompliance: Follow FTC guidelines, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "brainstorming",
            popup: true,
            image: `${Brainstroming}`,
        },
        tile09: {
            color: "linear-gradient(133deg, #5763D8 -13.36%, #828FEC 99.5%)",
            heading: "SALES EMAILS",
            content: "Emails that grab attention and close deals.",
            button: "Get my email templates",
            // prompt: `Sales Emails: Create a sequence of 2 sales email drafts. One email should be long-form, educational, and value-driven, offering insights or tips without focusing heavily on promotion. The second email should be promotional, highlighting the product/service with a clear call-to-action. Ensure alignment with the brand's voice and value proposition. Compliance: Follow FTC guidelines, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "emails",
            popup: true,
            image: `${Emails}`,
        },
        tile010: {
            color: "linear-gradient(133deg, #3BBC8E -13.36%, #60C99E 99.5%)",
            heading: "UPSELL PAGE",
            content: "Offers that increase your average order value.",
            button: "See my upsell page!",
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "upsell",
            popup: true,
            image: `${Upsell}`,
        },
        tile011: {
            color: "linear-gradient(133deg, #D34442 -13.36%, #E97175 99.5%)",
            heading: "YOUTUBE SCRIPTS",
            content: "Hook them. Keep them watching. Drive action.",
            button: "See my scripts!",
        //   prompt: `YouTube Scripts: Create a detailed YouTube video script (400-500 words) that provides educational, value-driven content with actionable tips, common mistakes, or insights. Include: An engaging introduction with a strong hook. A value-packed main section. A soft, natural product introduction. A light CTA for engagement (likes, comments, subscriptions). Ensure the content aligns with the brand's voice.\nCompliance: Follow FTC and YouTube content policies, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
            prompts: "youtube",
            popup: true,  
            image: `${Youtube}`,
        },
        tile1: {
            color: "linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(133deg, #3DA1FC -13.36%, #AAD6FE 99.5%)",
            heading: "Video Scripts",
            content: "Create engaging video scripts for your content.",
            button: "Write My Script",
            prompt: `**Video Scripts**: Generate video scripts for my brand.Content is created one category at a time, with the GPT generating video scripts in a 400-500 word transcript style for deeper engagement, including engaging language, a clear call to action, and a short accompanying social media post.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${VideoImg}`,
        },
        tile2: {
            color:"linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(133deg, #FD9946 -13.36%, #FED5B3 99.5%)",
            heading: "Carousel Posts",
            content: "Design captivating carousel posts for social media.",
            button: "Create My Post",
            prompt:`**Carousel Posts**: - Generated 3 facebook carousel posts, with each post containing 5 unique image captions to go along with each cohesive post text. Output should have headings for 'Post Text:' and 'Image Captions:' in h3 tag\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results. Please include an image suggestion for each of the carousel images.`,
            image: `${CarouselImg}`,
        },
        tile3: {
            color:"linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(128deg, #8CC448 3.45%, #B9DB8F 112.01%)",
            heading: "Stats/Figure Posts",
            content: "Share compelling stats and figures with ease.",
            button: "Generate My Post",
            prompt: `- **Authority Stats/Facts**: Write 3 Facebook posts about the products offered by the business and each post should contain an image captionstating the statistic or fact for enhanced credibility. Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${Stats}`,
        },
        tile4: {
            color:"linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(130deg, #BE45C3 2.69%, #DB97DD 94.91%)",
            heading: "Viral Headlines",
            content: "Craft attention-grabbing viral headlines effortlessly.",
            button: "Get My Headline",
            prompt: `**Viral Headlines**: Do a mix of russell brunson style headlines, output should be strictly just the headlines in a list with h3 tag separated by an hr tag don't generate any content.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${HeadlinesIMG}`,
        },
        tile5: {
            color:"linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(133deg, #FC3941 -13.36%, #FD959A 99.5%)",
            heading: "Quotes/Motivation",
            content: "Inspire your audience with motivational quotes.",
            button: "Find My Quote",
            prompt: `**Quotes/Motivation**: Write 2 Facebook posts in a a variety of length and style, with 2 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms. Please ensure each post contains a famous quote or motivational message that applies to the product / business / service.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${QuotesImg}`,
        },
        tile6: {
            color:"linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(133deg, #FD41AF -13.36%, #FEB3DF 99.5%)",
            heading: "Myth Buster",
            content: "Debunk common myths with simple facts.",
            button: "Bust My Myth",
            prompt: `Write 3 facebook posts that each debunk common myths or misconceptions pertaining to the product or service with simple facts. Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${MythBusterImg}`,
        },
        tile7: {
            color:"linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(128deg, #3DD7B7 3.45%, #B9DB8F 112.01%)",
            heading: "Facts/Education",
            content: "Deliver educational facts and insightful content.",
            button: "Share My Fact",
            prompt: `**Facts/Education**: Create 3 facebook posts each including an image caption stating the statistic or fact for enhanced credibility about my brand. Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${FactsImg}`,
        },
        tile8: {
            color:"linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(132deg, #940910 -38.39%, #F4525A 95.03%)",
            heading: "Brand Promotion",
            content: "Create impactful promotional content for your brand.",
            button: "Create My Promo",
            prompt: `**Promotional**: Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms. Outputs posts to be provided in sets of 3.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            image: `${PromotionalImg}`,
        },
        // tile9: {
        //     color:"#C5913E",
        //     heading: "Case Study",
        //     content: "Showcase success stories and testimonials easily.",
        //     button: "Write My Case Study",
        //     prompt: `**Case Study**: Generate case studies for my brand. Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
        //     image: `${CaseStudyImg}`,
        // },
        tile10: {
          color:"linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(126deg, #CD3A22 -1.77%, #E7806F 97.21%)",
          heading: "Product Promotion",
          content: "Create impactful promotional content for your product.",
          button: "Create Promotional Content",
          prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
          prompts: "Product",
          popup: true,
          image: `${ProductPromotion}`,
        }

    }
  return (
    <div className='flex gap-5 flex-wrap justify-center'>
        {
            Object.keys(content).map((key, index) => {
              const tile = content[key];
              return (
                <Tile
                  key={key}
                  index={key}
                  color={tile.color}
                  heading={tile.heading}
                  content={tile.content}
                  button={tile.button}
                  prompt={tile.prompt}
                  popup={tile.popup}
                  prompts={tile.prompts}
                  userData={promptPostStructure}
                  image={tile.image}
                />
              );
            })
        }
    </div>
  )
}

export default Tiles
