import LocalBrandForm from '../compements/form/BrandForm.js';

export default function HomeApp() {
    return (
    <>
        <div className='bg-cover bg-no-repeat bg-top w-full'>
            <div className = {`max-w-[1200px] mx-auto relative z-10`}>
                <LocalBrandForm />
            </div>
        </div>
    </>
    )
}