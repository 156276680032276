// src/layout/MainLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../compements/Sidebar';
import Header from '../compements/Header';

export default function MainLayout() {
  return (
    <div className='flex h-[100vh] bg-[#EDEDF4]'>
      <Sidebar />
      <div className='flex flex-col flex-1 md:pr-[20px] md:pb-[20px] '>
        <Header />
        <div className='overflow-hidden h-full bg-white md:rounded-[50px] px-[10px] md:px-0'>
          <div className='overflow-y-auto' style={{height: 'calc(100%)'}}>
            <div className='flex justify-center py-[30px] px-[10px] md:px-[20px]'>
            {/* Any route that matches children of this layout will appear here */}
            <Outlet />
            </div>
            </div>
        </div>
      </div>
    </div>
  );
}


