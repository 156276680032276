// src/components/Settings.js
import React, { useRef, useEffect, useState } from 'react';
import { Formio } from 'formiojs';
import { Link } from 'react-router-dom';
import api from '../api'; // Your axios instance configured for your API
import axios from 'axios';
import MD5 from 'crypto-js/md5';

// Replace with your actual Imgur Client ID.
const IMGUR_CLIENT_ID = 'YOUR_IMGUR_CLIENT_ID';

// Define the Form.io JSON schema for the settings form.
const settingsFormSchema = {
  display: 'form',
  components: [
    {
      "type": "textfield",
      "key": "email",
      "label": "Email",
      "placeholder": "Enter your email",
      "input": true,
      "validate": { "required": true }
    },
    {
      "type": "textfield",
      "key": "subject",
      "label": "Subject",
      "placeholder": "Enter the subject of your request",
      "input": true,
      "validate": { "required": true }
    },
    {
      "type": "textarea",
      "key": "message",
      "label": "Message",
      "placeholder": "Describe your issue or question",
      "input": true,
      "validate": { "required": true }
    },
    {
      "type": "button",
      "action": "submit",
      "label": "Contact Support",
      "theme": "primary",
      "input": false
    }
  ]
  
};

const Settings = () => {
  const formRef = useRef(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [uploading, setUploading] = useState(false);

  // Retrieve the user's email from local storage (saved on sign-in).
  const email = localStorage.getItem('email');
  let gravatarUrl = '';
  if (email) {
    const hash = MD5(email.trim().toLowerCase()).toString();
    // Gravatar URL with a size of 200px and default "identicon" style.
    gravatarUrl = `https://www.gravatar.com/avatar/${hash}?s=200&d=identicon`;
  }
  // Use the uploaded avatar if available; otherwise, fallback to Gravatar.
  const displayAvatar = avatarUrl || gravatarUrl;

  // Handle file selection and upload to Imgur.
  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setUploading(true);
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await axios.post('https://api.imgur.com/3/image', formData, {
        headers: {
          Authorization: `Client-ID ${IMGUR_CLIENT_ID}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.data && response.data.data && response.data.data.link) {
        setAvatarUrl(response.data.data.link);
        setSuccess('Avatar uploaded successfully.');
        setError('');
      } else {
        setError('Failed to upload avatar.');
      }
    } catch (err) {
      console.error('Error uploading avatar:', err);
      setError('Failed to upload avatar.');
    } finally {
      setUploading(false);
    }
  };

  // Create and mount the Form.io form for updating username and password.
  useEffect(() => {
    if (formRef.current) {
      Formio.createForm(formRef.current, settingsFormSchema)
        .then((form) => {
          form.on('submit', async (submission) => {
            try {
              // Prepare update data. Include avatar if available.
              const updateData = {
                username: submission.data.username,
                password: submission.data.newPassword,
                avatar: avatarUrl  // This field can be used by your backend to update the user's avatar.
              };

              // Retrieve the JWT token from local storage.
              const jwtToken = localStorage.getItem('jwt');
              if (!jwtToken) {
                throw new Error('User is not authenticated.');
              }

              // Send the PUT request with the Authorization header.
              const response = await api.put('/api/users/me', updateData, {
                headers: { Authorization: `Bearer ${jwtToken}` }
              });
              console.log('Settings updated successfully:', response.data);
              setSuccess('Settings updated successfully.');
              setError('');
            } catch (err) {
              console.error('Error updating settings:', err);
              setError(
                err.response?.data?.message ||
                err.message ||
                'Failed to update settings. Please try again.'
              );
              setSuccess('');
            }
          });
        })
        .catch((err) => {
          console.error('Error creating form:', err);
          setError('An error occurred while loading the form.');
        });
    }
  }, [formRef, avatarUrl]);

  return (
    <div className='bg-signIn bg-cover rounded-[50px] w-full mx-auto min-h-full'>
    <div className='max-w-[900px] mx-auto w-full sing-up-in py-[50px] px-[20px]'>
      <h2 className='text-[35px] font-[700] text-center mb-[30px] text-white'>Contact Support</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {success && <p style={{ color: 'green' }}>{success}</p>}

      {/* Display the user's avatar */}
      {displayAvatar && (
        <img
          src={displayAvatar}
          alt="User Avatar"
          style={{
            borderRadius: '50%',
            marginBottom: '20px',
            width: '200px',
            height: '200px',
            objectFit: 'cover'
          }}
        />
      )}

      {/* File input for uploading a new avatar */}
      {/* <div style={{ marginBottom: '20px' }}>
        <label htmlFor="avatar-upload" style={{ marginRight: '10px' }}>
          Upload Avatar:
        </label>
        <input
          id="avatar-upload"
          type="file"
          accept="image/*"
          onChange={handleAvatarUpload}
          disabled={uploading}
        />
      </div> */}

      {/* Form.io form for updating username and password */}
      <div ref={formRef} className="space-y-4" style={{ marginTop: '20px' }}></div>
    </div>
    </div>
  );
};

export default Settings;
