import Signup from "../compements/SignUp"

function SignUp() {
  return (
    <>
      <Signup/>
    </>
  )
}

export default SignUp
