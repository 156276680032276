// src/components/SignIn.js
import React, { useRef, useEffect, useState } from 'react';
import { Formio } from 'formiojs';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from "../media/Logfav-White.svg";
import bg from "../media/sign-in.svg";
import api from '../api'; // your axios instance

// Define the Form.io JSON schema for the sign-in form.
const signInFormSchema = {
  display: 'form',
  components: [
    {
      type: 'textfield',
      key: 'identifier',
      label: 'Email / Username',
      placeholder: 'Enter your email or username',
      input: true,
      validate: {
        required: true
      }
    },
    {
      type: 'password',
      key: 'password',
      label: 'Password',
      placeholder: 'Enter your password',
      input: true,
      validate: {
        required: true
      }
    },
    {
      type: 'checkbox',
      key: 'termsAccepted',
      label: "I acknowledge and agree that I am solely responsible for reviewing and verifying the accuracy, legality, and compliance of any marketing copy generated by this chatbot. I understand that the chatbot provides content suggestions based on user input and does not guarantee compliance with legal, regulatory, or industry standards. I accept full legal responsibility for the use of any content generated.",
      input: true,
      validate: {
        required: true,
        customMessage: "Terms of conditions are required"
      }
    },
    {
      type: 'button',
      action: 'submit',
      label: 'Sign In',
      theme: 'primary',
      input: false  // Prevents the button from being included in the submission payload.
    }
  ]
};

const SignIn = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    if (formRef.current) {
      // Create and mount the Form.io form.
      Formio.createForm(formRef.current, signInFormSchema)
        .then((form) => {
          form.on('submit', async (submission) => {
            try {
              // submission.data will include the identifier and password fields.
              const response = await api.post('/api/auth/local', submission.data);
              console.log('Login successful', response.data);
              
              // Extract user data from the response.
              const user = response.data.user;
              const subscriptionStatus = user.subscriptionStatus;
              const trialEnd = user.trialEnd; // Assume this is a string in a recognized format

              // Function to check if the trialEnd is in the future.
              const isTrialActive = () => {
                if (trialEnd) {
                  const trialEndDate = new Date(trialEnd);
                  const now = new Date();
                  return trialEndDate > now;
                }
                return false;
              };

              // If trialEnd exists and is in the future, skip subscriptionStatus check.
              if (isTrialActive()) {
                localStorage.setItem('jwt', response.data.jwt);
                localStorage.setItem('user', JSON.stringify(user));
                toast.success("Sign in successful!");
                navigate('/');
                return;
              }
              
              // If trial is not active (empty, null, or past), check subscriptionStatus.
              if (subscriptionStatus === 'trialing' || subscriptionStatus === 'paid' || subscriptionStatus === 'draft') {
                localStorage.setItem('jwt', response.data.jwt);
                localStorage.setItem('user', JSON.stringify(user));
                toast.success("Sign in successful!");
                navigate('/');
              } else {
                alert("Your subscription is inactive. Please subscribe to continue.");
                window.location.href = "https://buy.stripe.com/6oEdTP5i41SR1he6oB";
              }
            } catch (err) {
              console.error(err);
              setError('Invalid credentials. Please try again.');
              toast.error("Invalid credentials. Please try again.");
            }
          });
        })
        .catch((err) => {
          console.error('Error creating form:', err);
        });
    }
  }, [formRef, navigate]);

  return (
    <div className='bg-signIn bg-cover max-w-full mx-auto min-h-[100vh]'>
      <div className='max-w-[750px] w-full sing-up-in mx-auto py-[50px] px-[20px]'>
        <img
          src={logo}
          alt="Profile"
          className="w-full h-auto mx-auto mb-[50px] max-w-[200px]"
        />
        <h2 className='text-[35px] font-[700] text-center mb-[30px] text-white'>Sign In</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {/* The Form.io form will be mounted inside this div */}
        <div ref={formRef} className="space-y-4"></div>
        <p className='text-center text-white'>
          Don’t have an account? <Link to="/signup">Sign Up</Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
