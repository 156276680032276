// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  // Check for a JWT token in local storage (or check your auth context/state)
  const token = localStorage.getItem('jwt');

  if (!token) {
    // Not authenticated, redirect to Sign In page
    return <Navigate to="/signin" replace />;
  }

  // Authenticated, render the requested component
  return children;
};

export default ProtectedRoute;
