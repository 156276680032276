import React from 'react';
import { useNavigate } from 'react-router-dom';
import PromotionPopup from '../compements/PromotionPopup.js';
function Tile(props) {
    const navigate = useNavigate();
    function goToNextPage(){
      // const texTareaPrompt = document.querySelector(`.prompt-${props.index}`).value
      const prompt = {
          heading: props.heading, 
          prompt: props.prompt,
          userData: props.userData
      }
      navigate('/results', { state: prompt })
    }
  return (
    <div className='w-[100%] md:w-[45%] lg:w-[30%] bg-cover bg-no-repeat bg-center flex flex-col items-center p-[30px] px-[0] pt-0 gap-[15px] rounded-[40px] mt-[50px]' style={{backgroundImage: `${props.color}`}}>
        <img src={props.image} className='h-auto max-w-full w-[120px] mt-[-50px]'/>
        <div className='px-[20px]'>
            <h1 className='text-white text-center font-poppins text-[25px] font-[800] uppercase'>{props.heading}</h1>
            <p className='text-center font-poppins text-[21px] text-white m-0'>{props.content}</p>
        </div>
        {props.popup ? 
        <><PromotionPopup button = {props.button} heading = {props.heading} type = {props.prompts} userData = {props.userData}/></>
        :
        <>
        <button className='font-poppins text-[18px] font-bold text-white px-[10px]' onClick={()=> {goToNextPage()}}>{`${props.button} >`}</button>
        </>
        }
    </div>
  )
}

export default Tile
