// src/components/Header.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import placeHolder from '../media/person-placeholder.png';
import hamburger from "../media/Nav/Hamburger.svg"
import logo from "../media/Logfav.svg"
export default function Header() {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  // Toggle dropdown visibility when the profile image is clicked.
  const toggleDropdown = () => {
    setShowDropdown(prev => !prev);
  };

  // Logout by removing the JWT from localStorage and navigating to sign in.
  const handleLogout = () => {
    localStorage.removeItem('jwt');
    // Optionally, remove additional user data here.
    navigate('/signin'); // Update this path to your actual sign-in route.
  };
  
  const openSideBar = (val) => {
    const navBar = document.getElementById('navBar')
    console.log(navBar)
      if(val){
        navBar.classList.remove('hidden')
      }
      else{
        navBar.classList.add('hidden')
      }
  }
  return (
    <header
      style={styles.header}
      className="min-h-[50px] md:min-h-[70px] flex items-center justify-between md:py-[10px] px-[20px] relative"
    >
      {/* Left side: search bar (or any other items) */}
      <div>
        {/* <input
          type="text"
          className='hidden md:block'
          placeholder="Search for anything..."
          style={styles.searchInput}
        /> */}
        <img onClick={() => {const navBar = document.getElementById('navBar');const overlay = document.getElementById('overlay'); const Cross = document.getElementById('cross'); navBar.classList.remove('hidden'); overlay.classList.remove('hidden'); Cross.classList.remove('hidden')}} src={hamburger} id='hamburger' alt="Profile" className="md:hidden w-full h-auto max-w-[25px]" />
      </div>
      <img
            src={logo}
            alt="Logo"
            className="w-full h-auto max-w-[50px]"
          />
      {/* Right side: profile section */}
      <div className="relative">
        <div
          className="h-[40px] w-[40px] md:h-[50px] md:w-[50px]  overflow-hidden flex items-center justify-center rounded-full cursor-pointer"
          onClick={toggleDropdown}
        >
          <img
            src={placeHolder}
            alt="Profile"
            className="w-full h-auto max-w-[50px]"
          />
        </div>
        {/* Dropdown menu */}
        {showDropdown && (
          <div className="absolute right-0 mt-2 w-40 bg-white border rounded shadow-lg z-50">
            <button
              onClick={handleLogout}
              className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </header>
  );
}

const styles = {
  searchContainer: {
    flex: 1,
    maxWidth: '300px',
  },
  searchInput: {
    width: '100%',
    padding: '8px 12px',
    borderRadius: '90px',
    border: "1px solid rgba(0, 0, 0, 0.25)",
    fontSize: '14px',
    background: 'unset',
  },
  header: {
    // You can add additional header styling here if needed.
  },
};