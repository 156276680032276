// src/components/Sidebar.js
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Home from '../media/Nav/home 02.svg';
import Form from '../media/Nav/notes 01.svg';
import Content from '../media/Nav/grid 01.svg';
import Analytics from '../media/Nav/chart-notification.svg';
import Settings from '../media/Nav/Vector.svg';
import PlusBtn from '../media/Nav/Button.svg';
import { Link } from 'react-router-dom';
import activeBG from '../media/Nav/nav-bg.svg';
import { toast } from 'react-toastify';
import cross from "../media/Nav/Cross.svg";

export default function Sidebar() {
  const location = useLocation();

  // Common class names for all nav items
  const baseClass =
    "flex md:justify-center h-[40px] md:h-[60px] transition-all rounded-[50%] relative w-full justify-between pl-[20px] pr-[10px] md:px-0 items-center";
  const activeClass = "activeLink " + baseClass;
  
  // Tooltip styling classes (using Tailwind CSS)
  const tooltipClass =
    "absolute bottom-[-30px] left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded px-2 py-1 opacity-0 transition-opacity duration-200 pointer-events-none";

  return (
    <>
      <div onClick={() => {
          const overlay = document.getElementById('overlay'); 
          overlay.classList.add('hidden'); 
          const navBar = document.getElementById('navBar'); 
          navBar.classList.add('hidden')
        }} 
        id='overlay' 
        className='h-full w-full fixed bg-[#0000008a] z-[99] hidden md:hidden'
      ></div>
      <aside id='navBar' className='w-fit md:w-[70px] bg-[#EDEDF4] h-full hidden md:block fixed md:relative z-[100] side-bar'>
        <img onClick={() => {
            const overlay = document.getElementById('overlay'); 
            overlay.classList.add('hidden'); 
            const navBar = document.getElementById('navBar'); 
            navBar.classList.add('hidden')
          }} 
          src={cross} 
          alt="Profile" 
          className="hidden w-full h-auto max-w-[25px] md:hidden mt-[15px] ml-[15px]" 
          id="cross" 
        />
        <Link to="/content" className='hidden md:block'>
          <img src={PlusBtn} className='h-auto max-w-full w-[30px] md:w-[40px] mt-[20px] ml-[20px]' />
        </Link>
        <nav className='flex flex-col items-center justify-center gap-[50px] mt-[100px] w-full'>
          {/* Home NavLink */}
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? activeClass : baseClass) + " group"}
          >
            <p className="mr-[50px] md:hidden">Dashboard</p>
            <img src={activeBG} className='h-auto max-w-full w-[70px] md:w-[70px] absolute z-0 top-[50%] translate-y-[-50%] nav-bg hidden right-0' />
            <img src={Home} className='h-auto max-w-full w-[25px] md:w-[30px] z-10 nav-icon' />
            <span className={tooltipClass + " md:group-hover:opacity-100"}>Dashboard</span>
          </NavLink>
          
          {/* Form NavLink */}
          <NavLink
            to="/form"
            className={({ isActive }) => (isActive ? activeClass : baseClass) + " group"}
          >
            <p className="mr-[50px] md:hidden">Brand Data</p>
            <img src={activeBG} className='h-auto max-w-full w-[70px] md:w-[70px] absolute z-0 top-[50%] translate-y-[-50%] nav-bg hidden right-0' />
            <img src={Form} className='h-auto max-w-full w-[25px] md:w-[30px] z-10 nav-icon' />
            <span className={tooltipClass + " md:group-hover:opacity-100"}>Brand Data</span>
          </NavLink>
          
          {/* Content NavLink (third item) */}
          <NavLink
            to="/content"
            className={({ isActive }) => (isActive ? activeClass : baseClass) + " group"}
          >
            <p className="mr-[50px] md:hidden">Content Type</p>
            <img src={activeBG} className='h-auto max-w-full w-[70px] md:w-[70px] absolute z-0 top-[50%] translate-y-[-50%] nav-bg hidden right-0' />
            <img src={Content} className='h-auto max-w-full w-[25px] md:w-[30px] z-10 nav-icon' />
            <span className={tooltipClass + " md:group-hover:opacity-100"}>Content Type</span>
          </NavLink>
          
          {/* Second-to-last NavLink: destination is "/content" but active when on "/results" */}
          <NavLink
            to="/content"
            onClick={()=> {toast.error("Please Select the content type");}}
            className={({ isActive }) =>
              (location.pathname === '/results')
                ? activeClass + " group"
                : baseClass + " group"
            }
          >
            <p className="mr-[50px] md:hidden">Results</p>
            <img src={activeBG} className='h-auto max-w-full w-[70px] md:w-[70px] absolute z-0 top-[50%] translate-y-[-50%] nav-bg hidden right-0' />
            <img src={Analytics} className='h-auto max-w-full w-[25px] md:w-[30px] z-10 nav-icon' />
            <span className={tooltipClass + " md:group-hover:opacity-100"}>Results</span>
          </NavLink>
          
          {/* Settings NavLink */}
          <NavLink
            to="/settings"
            className={({ isActive }) => (isActive ? activeClass : baseClass) + " group"}
          >
            <p className="mr-[50px] md:hidden">Settings</p>
            <img src={activeBG} className='h-auto max-w-full w-[70px] md:w-[70px] absolute z-0 top-[50%] translate-y-[-50%] nav-bg hidden right-0' />
            <img src={Settings} className='h-auto max-w-full w-[25px] md:w-[30px] z-10 nav-icon' />
            <span className={tooltipClass + " md:group-hover:opacity-100"}>Settings</span>
          </NavLink>
        </nav>
      </aside>
    </>
  );
}
