// import React, { useState } from 'react';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Formio } from 'formiojs';
import { useNavigate } from 'react-router-dom';
import "../styles/Popup.css"

// Modal style configuration (you can adjust the styles as needed)
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Bind modal to the root element of your app
Modal.setAppElement('#root');

const ProductPromoFormModal = (propsB) => {
    const navigate = useNavigate();
    const [submissionData, setSubmissionData] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState(null);
    var subData = {};
    const getStoredData = () => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
          return JSON.parse(savedData); // Parse the string back into an object
        }
        return null; // Return null if no data is found
      };
    const generatePrompt = (props) => {
      if ( propsB.type == "checkout"){
        const extraFormResponse = {
          "My specific product/service is": `${props.productService}`,
          "My pricing and payment options are": `${props.pricePaymentPlans}`,
          "My guarantees, warranties, or refund policies are": `${props.guaranteesWarrantiesRefundPolicies}`,
          "My common customer objections are": `${props.commonObjections}`,
          "My security/trust badges to be featured are": `${props.securityTrustBadges}`,
          "My verified testimonials or reviews are": `${props.testimonialsReviews}`,
          "My urgency or scarcity elements are": `${props.urgencyScarcity}`
        };
        return {
          heading: propsB.heading,
          prompt: `Checkout Page Copywriting: Write persuasive, conversion-focused copy for a checkout page. Highlight key benefits, address customer concerns, and include a strong call-to-action that reassures buyers about security and value. Add trust signals (guarantees, security badges) to build confidence. Ensure the tone aligns with the brand data provided in the JSON. \nCompliance: Follow FTC guidelines, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
          userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else if (propsB.type == "facebook"){
        const extraFormResponse = {
          "The product/service being promoted is": `${props.productPromoted}`,
          "The primary goal of the ad is": `${props.primaryAdGoal}`,
          "The special offers, discounts, or bonuses are": `${props.specialOffers}`,
          "The top 3 benefits to highlight are": `${props.topBenefits}`,
          "The common objections to address are": `${props.commonObjectionsAd}`,
          "The past ad campaigns for reference are": `${props.pastAdCampaigns}`,
          "The specific call-to-action is": `${props.specificCallToAction}`,
          "The target landing page for the ad is": `${props.targetLandingPage}`
        }        
        return {
            heading: propsB.heading,
            prompt: `Facebook Ads Content: Create 2 engaging Facebook ad copy variations. One ad should be short and concise, focusing on quick engagement and conversion. The other should be longer-form, offering more context, storytelling, or detailed benefits. Each ad should include a captivating headline, compelling body text, and a clear call-to-action. Leverage the brand data provided in the JSON to tailor the message to the target audience. \nCompliance: Follow FTC and Facebook Ads policies, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else if (propsB.type == "google"){
        const extraFormResponse = {
          "The product/service being advertised is": `${props.productAdvertised}`,
          "The keywords or search phrases to target are": `${props.targetKeywords}`,
          "The specific promotions, discounts, or limited-time offers are": `${props.promotionsDiscountsOffers}`,
          "The main call-to-action is": `${props.mainCallToAction}`,
          "The competitors to differentiate from are": `${props.competitors}`,
          "The target landing page for the ad is": `${props.targetLandingPage}`
        }
        return {
            heading: propsB.heading,
            prompt: `Google Ads Content: Develop 2 Google Ads copy variations, each with a short, attention-grabbing headline and a concise description. Focus on key benefits, optimized for search intent, with a clear call-to-action. Use the brand data provided in the JSON to ensure relevance.\nCompliance: Follow FTC and Google Ads policies, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else if (propsB.type == "instagram"){
        const extraFormResponse = {
          "The goal of the Reel is": `${props.reelGoal}`,
          "The product/service or message featured is": `${props.featuredMessage}`,
          "The specific visual styles, trends, or transitions to include are": `${props.visualStyles}`,
          "The preferred call-to-action is": `${props.preferredCTA}`,
          "The key benefits or pain points to highlight are": `${props.benefitsPainPoints}`
        }
        return {
            heading: propsB.heading,
            prompt: `Instagram Reel Scripts: Generate a short, energetic script for an Instagram Reel video (30-60 seconds). The script should include dynamic, engaging language with clear cues for visuals or transitions. Incorporate a compelling call-to-action and reflect the brand tone using data provided in the JSON. its only one content output should not have ant hr tags.\nCompliance: Follow FTC and Instagram content policies, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else if (propsB.type == "landingpage"){
        const extraFormResponse = {
          "The product/service this page is for is": `${props.productServicePage}`,
          "The primary goal of the landing page is": `${props.primaryGoalPage}`,
          "The specific pain points or objections to address are": `${props.painPointsObjections}`,
          "The key value proposition is": `${props.keyValueProposition}`,
          "The verified testimonials, reviews, or case studies to include are": `${props.testimonialsReviews}`,
          "The urgency or scarcity elements to highlight are": `${props.urgencyScarcity}`
        }
        return {
            heading: propsB.heading,
            prompt: `Landing Page Copy: Develop a comprehensive, long-form sales page that includes: A strong headline and subheadline. An engaging introduction. Sections highlighting key benefits, features, and differentiators. Emotional triggers addressing pain points. Trust signals (testimonials, guarantees, security badges). FAQs to overcome objections. Strong call-to-action (CTA) buttons throughout. Ensure the tone aligns with the brand data provided in the JSON.\nCompliance: Follow FTC guidelines and landing page best practices, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else if (propsB.type == "pdf"){
        const extraFormResponse = {
          "The topic or focus of the lead magnet is": `${props.leadMagnetTopic}`,
          "The key problem it solves for the audience is": `${props.leadMagnetProblem}`,
          "The specific steps, strategies, or tips to include are": `${props.leadMagnetSteps}`,
          "The statistics, case studies, or data points to add are": `${props.leadMagnetStats}`,
          "The preferred tone is": `${props.leadMagnetTone}`,
          "The main call-to-action at the end of the PDF is": `${props.leadMagnetCTA}`
        }        
        return {
            heading: propsB.heading,
            prompt: `Lead Magnet PDF: Create an outline and detailed content for a lead magnet PDF (e-book, guide, or report) that provides actionable insights. Include an engaging title, introduction, multiple sections with clear headings, and a call-to-action. Integrate brand data from the JSON.\nCompliance: Follow FTC guidelines, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else if (propsB.type == "linkedin"){
        const extraFormResponse = {
          "The key message or insight each post should convey is": `${props.postKeyMessage}`,
          "The thought leadership points to emphasize are": `${props.thoughtLeadershipPoints}`,
          "The trending industry topics to reference are": `${props.trendingIndustryTopics}`,
          "The preferred engagement style is": `${props.engagementStyle}`,
          "The achievements, stats, or case studies to include are": `${props.achievementsStatsCaseStudies}`,
          "The call-to-action for each post is": `${props.postCTA}`
        }
        return {
            heading: propsB.heading,
            prompt: `LinkedIn Posts: Generate 2 professional LinkedIn posts that position me that share education / information about my industry and position me as a thought leader. One post should be educational and value-driven, providing actionable tips or industry insights without being overly promotional. The second post can be a little more promotional but still giving education, highlighting a product or service with a clear call-to-action. Ensure the messaging is authentic and aligns with the brand's voice. Compliance: Follow FTC and LinkedIn content policies, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else if (propsB.type == "brainstorming"){
        const extraFormResponse = {
          "The current products/services being offered are": `${props.currentProductsServices}`,
          "The customer feedback or recurring requests received are": `${props.customerFeedback}`,
          "The gaps in current offerings to fill are": `${props.offeringGaps}`,
          "The preferred product type is": `${props.productPreference}`,
          "The emerging trends to explore are": `${props.emergingTrends}`,
          "The goal for these new offers is": `${props.offerGoal}`
        }
        return {
            heading: propsB.heading,
            prompt: `Offer Brainstorming: Generate 5 creative ideas for new products or services that align with the brand's niche. Each idea should include: A catchy name. A brief description. Key customer benefits. How it fits within the brand's current offerings. Ensure relevance using brand data from the JSON.\nCompliance: Follow FTC guidelines, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else if (propsB.type == "emails"){
        const extraFormResponse = {
          "The product/service being promoted is": `${props.emailProductService}`,
          "The main goal of the email sequence is": `${props.emailSequenceGoal}`,
          "The common objections or concerns of prospects are": `${props.emailObjectionsConcerns}`,
          "The limited-time offers, bonuses, or deadlines to mention are": `${props.limitedTimeOffers}`,
          "The verified testimonials, case studies, or stats to include are": `${props.testimonialsCaseStudiesStats}`,
          "The preferred tone for the email sequence is": `${props.preferredTone}`
        }
        return {
            heading: propsB.heading,
            prompt: `Sales Emails: Create a sequence of 2 sales email drafts. One email should be long-form, educational, and value-driven, offering insights or tips without focusing heavily on promotion. The second email should be promotional, highlighting the product/service with a clear call-to-action. Ensure alignment with the brand's voice and value proposition. Compliance: Follow FTC guidelines, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else if (propsB.type == "upsell"){
        const extraFormResponse = {
          "The upsell product/service is": `${props.upsellProductService}`,
          "The primary product the customer just purchased is": `${props.primaryProductPurchased}`,
          "The price point for the upsell is": `${props.upsellPricePoint}`,
          "The key benefit of the upsell is": `${props.upsellKeyBenefit}`,
          "The objections to address are": `${props.upsellObjections}`,
          "The bonuses or limited-time incentives to include are": `${props.upsellBonusesIncentives}`
        }
        return {
            heading: propsB.heading,
            prompt: `Upsell Page: Develop persuasive upsell page copy that encourages customers to consider premium or additional products/services. Clearly present added value, comparison points, and address objections with a compelling call-to-action. \nCompliance: Follow FTC guidelines and web copy best practices, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else if (propsB.type == "youtube"){
        const extraFormResponse = {
          "The topic or main focus of the video is": `${props.videoTopic}`,
          "The key message or takeaway for viewers is": `${props.videoKeyMessage}`,
          "The specific points, tips, or stats to include are": `${props.videoPointsTipsStats}`,
          "The type of visuals or on-screen text to be used is": `${props.videoVisuals}`,
          "Whether the script should follow trending formats/styles is": `${props.videoTrendingFormats}`,
          "The call-to-action at the end is": `${props.videoCTA}`
        }        
        return {
            heading: propsB.heading,
            prompt: `YouTube Scripts: Create a detailed YouTube video script (400-500 words) that provides educational, value-driven content with actionable tips, common mistakes, or insights. Include: An engaging introduction with a strong hook. A value-packed main section. A soft, natural product introduction. A light CTA for engagement (likes, comments, subscriptions). Ensure the content aligns with the brand's voice.\nCompliance: Follow FTC and YouTube content policies, avoiding unverified claims, exaggerated promises, or fabricated testimonials.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
      else{
        const extraFormResponse = {
          "The product name is": `${props.productName}`,
          "The product description is": `${props.productDescription}`,
          "The product URL is": `${props.productURL}`,
          "The product's key features are": `${props.productKeyFeatures}`
        }        
        return {
            heading: propsB.heading,
            prompt: `**Product Promotion**: Each ad will be crafted to maximize variety in length and style, including 1 long ad rich in detail and 1 medium-length ads with unique messaging styles. Ads are provided in sets of two and the final ad in each set is the longest, Ads avoid embedded links and instead clearly display URLs for social platforms.\n**Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            userData: {...propsB.userData, ...extraFormResponse},
        }
      }
    }
    // Open the modal
    const openModal = () => {
        setModalIsOpen(true);
    };

    // Close the modal
    const closeModal = () => {
        setModalIsOpen(false);
    };
    var formJSON;
    if(propsB.type == "checkout"){
    formJSON = {
      "components": [
        {
          "type": "textarea",
          "key": "productService",
          "label": "Product/Service Details",
          "placeholder": "What specific product/service is this for?",
          "description": "Example: 'XYZ Pro Software for digital marketing professionals.'",
          "input": true,
          "validate": {
            "required": true
          }
        },
        {
          "type": "textarea",
          "key": "pricePaymentPlans",
          "label": "Pricing & Payment Options",
          "placeholder": "What is the price, and are there any payment plans?",
          "description": "Example: '$99.99 one-time payment or $9.99/month subscription.'",
          "input": true,
          "validate": {
            "required": true
          }
        },
        {
          "type": "textarea",
          "key": "guaranteesWarrantiesRefundPolicies",
          "label": "Guarantees, Warranties & Refund Policies",
          "placeholder": "Are there guarantees, warranties, or refund policies to highlight?",
          "description": "Example: '30-day money-back guarantee.'",
          "input": true,
          "validate": {
            "required": true
          }
        },
        {
          "type": "textarea",
          "key": "commonObjections",
          "label": "Common Customer Objections",
          "placeholder": "What common objections do customers have before purchasing?",
          "description": "Example: 'Concerns about price, effectiveness, or product fit.'",
          "input": true,
          "validate": {
            "required": true
          }
        },
        {
          "type": "textarea",
          "key": "securityTrustBadges",
          "label": "Security/Trust Badges",
          "placeholder": "What security/trust badges should be featured?",
          "description": "Example: 'SSL secured, Trusted by industry leaders, Verified by Trustpilot.'",
          "input": true,
          "validate": {
            "required": true
          }
        },
        {
          "type": "textarea",
          "key": "testimonialsReviews",
          "label": "Testimonials & Reviews",
          "placeholder": "Are there verified testimonials or reviews to include?",
          "description": "Example: 'Customer testimonials, success stories, or case studies.'",
          "input": true,
          "validate": {
            "required": true
          }
        },
        {
          "type": "textarea",
          "key": "urgencyScarcity",
          "label": "Urgency & Scarcity Elements",
          "placeholder": "Are there any urgency or scarcity elements (limited stock, deadlines)?",
          "description": "Example: 'Limited time offer, only 5 left, sale ends midnight.'",
          "input": true,
          "validate": {
            "required": true
          }
        },
        {
          "type": "button",
          "action": "submit",
          "label": "Submit",
          "theme": "primary"
        }
      ]
    }
    }
    else if(propsB.type == "facebook"){
      formJSON = {
        "components": [
          {
            "type": "textarea",
            "key": "productPromoted",
            "label": "Product/Service Promoted",
            "placeholder": "What product/service is being promoted?",
            "description": "Example: 'XYZ Software' or 'ABC Consulting Service'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "primaryAdGoal",
            "label": "Primary Ad Goal",
            "placeholder": "What’s the primary goal of the ad (sales, lead generation, awareness)?",
            "description": "Example: 'Sales, Lead Generation, or Brand Awareness'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "specialOffers",
            "label": "Special Offers / Discounts / Bonuses",
            "placeholder": "Are there any special offers, discounts, or bonuses?",
            "description": "Example: '20% off, Buy One Get One Free, or a complimentary consultation'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "topBenefits",
            "label": "Top 3 Benefits",
            "placeholder": "What are the top 3 benefits you want to highlight?",
            "description": "Example: 'Cost savings, Efficiency, Ease of use'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "commonObjectionsAd",
            "label": "Common Objections",
            "placeholder": "What common objections should the ad address?",
            "description": "Example: 'Concerns about price, credibility, or product effectiveness'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "pastAdCampaigns",
            "label": "Past Ad Campaigns",
            "placeholder": "Are there past ad campaigns that performed well for reference?",
            "description": "Example: 'Include links or brief descriptions of successful past campaigns'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "specificCallToAction",
            "label": "Specific Call-to-Action",
            "placeholder": "What specific call-to-action do you want to include?",
            "description": "Example: 'Sign up now, Learn More, or Buy Now'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "targetLandingPage",
            "label": "Target Landing Page",
            "placeholder": "What’s the target landing page for the ad?",
            "description": "Example: 'www.yourwebsite.com/landing-page'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "button",
            "action": "submit",
            "label": "Submit",
            "theme": "primary"
          }
        ]
      }
      
    }
    else if(propsB.type == "google"){
      formJSON = {
        "components": [
          {
            "type": "textarea",
            "key": "productAdvertised",
            "label": "Product/Service Being Advertised",
            "placeholder": "What product/service is being advertised?",
            "description": "Example: 'XYZ Software' or 'ABC Consulting Service'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "targetKeywords",
            "label": "Target Keywords/Search Phrases",
            "placeholder": "What keywords or search phrases should the ads target?",
            "description": "Example: 'digital marketing tools, online advertising, lead generation tips'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "promotionsDiscountsOffers",
            "label": "Promotions, Discounts, or Limited-Time Offers",
            "placeholder": "Are there specific promotions, discounts, or limited-time offers?",
            "description": "Example: '15% off for new subscribers or a limited time bonus offer'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "mainCallToAction",
            "label": "Main Call-to-Action",
            "placeholder": "What’s the main call-to-action (learn more, buy now, get a quote)?",
            "description": "Example: 'Learn More, Buy Now, or Get a Quote'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "competitors",
            "label": "Competitors to Differentiate From",
            "placeholder": "Are there any competitors you’d like to differentiate from?",
            "description": "Example: 'Competitor A, Competitor B, etc.'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "targetLandingPage",
            "label": "Target Landing Page",
            "placeholder": "What’s the target landing page for the ad?",
            "description": "Example: 'www.yourwebsite.com/landing-page'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "button",
            "action": "submit",
            "label": "Submit",
            "theme": "primary"
          }
        ]
      }
      
    }
    else if(propsB.type == "instagram"){
      formJSON = {
        "components": [
          {
            "type": "textarea",
            "key": "reelGoal",
            "label": "Goal of the Reel",
            "placeholder": "What’s the goal of the Reel (educate, entertain, promote)?",
            "description": "Example: 'Educate, entertain, or promote a product/service or message.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "featuredMessage",
            "label": "Featured Product/Service or Message",
            "placeholder": "What product/service or message is being featured?",
            "description": "Example: 'Showcase a new product launch or share a motivational message.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "visualStyles",
            "label": "Visual Styles & Transitions",
            "placeholder": "Are there specific visual styles, trends, or transitions to include?",
            "description": "Example: 'Include fast cuts, trendy filters, or smooth transitions.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "preferredCTA",
            "label": "Preferred Call-to-Action",
            "placeholder": "Is there a preferred CTA (follow, visit website, shop now)?",
            "description": "Example: 'Follow for more tips, visit our website, or shop now.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "benefitsPainPoints",
            "label": "Key Benefits or Pain Points",
            "placeholder": "Are there key benefits or pain points to highlight?",
            "description": "Example: 'Highlight how the product solves common pain points or improves life quality.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "button",
            "action": "submit",
            "label": "Submit",
            "theme": "primary"
          }
        ]
      }
      
    }
    else if(propsB.type == "landingpage"){
      formJSON = {
        "components": [
          {
            "type": "textarea",
            "key": "productServicePage",
            "label": "Product/Service for Page",
            "placeholder": "What product/service is this page for?",
            "description": "Example: 'XYZ Software for digital marketing' or a similar product/service.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "primaryGoalPage",
            "label": "Primary Goal of the Landing Page",
            "placeholder": "What’s the primary goal of the landing page (sales, lead capture)?",
            "description": "Example: 'Sales' or 'Lead Capture'.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "painPointsObjections",
            "label": "Pain Points / Objections",
            "placeholder": "Are there specific pain points or objections to address?",
            "description": "Example: 'Address common objections related to cost, complexity, or suitability.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "keyValueProposition",
            "label": "Key Value Proposition",
            "placeholder": "What’s the key value proposition?",
            "description": "Example: 'Deliver unmatched efficiency and savings with our unique approach.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "testimonialsReviews",
            "label": "Testimonials, Reviews, or Case Studies",
            "placeholder": "Are there verified testimonials, reviews, or case studies to include?",
            "description": "Example: 'Include customer testimonials, positive reviews, or case studies that build credibility.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "urgencyScarcity",
            "label": "Urgency / Scarcity Elements",
            "placeholder": "Should any urgency or scarcity elements be highlighted?",
            "description": "Example: 'Limited-time offer, only a few spots left, or countdown timer details.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "button",
            "action": "submit",
            "label": "Submit",
            "theme": "primary"
          }
        ]
      }
      
    }
    else if(propsB.type == "pdf"){
      formJSON = {
        "components": [
          {
            "type": "textarea",
            "key": "leadMagnetTopic",
            "label": "Lead Magnet Topic/Focus",
            "placeholder": "What’s the topic or focus of the lead magnet?",
            "description": "Example: 'Digital Marketing Strategies for Beginners' or another specific focus.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "leadMagnetProblem",
            "label": "Key Problem Solved",
            "placeholder": "What key problem does it solve for the audience?",
            "description": "Example: 'It helps solve the challenge of generating qualified leads through digital marketing.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "leadMagnetSteps",
            "label": "Steps, Strategies, or Tips",
            "placeholder": "Are there specific steps, strategies, or tips to include?",
            "description": "Example: 'Include actionable steps such as setting up a lead funnel, email list building, and social media engagement.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "leadMagnetStats",
            "label": "Statistics / Case Studies / Data Points",
            "placeholder": "Should any statistics, case studies, or data points be added?",
            "description": "Example: 'Include relevant statistics or brief case studies to validate the approach.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "leadMagnetTone",
            "label": "Preferred Tone",
            "placeholder": "What’s the preferred tone (educational, motivational, casual)?",
            "description": "Example: 'Choose a tone such as educational, motivational, or casual depending on your audience.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "leadMagnetCTA",
            "label": "Main Call-to-Action",
            "placeholder": "What’s the main CTA at the end of the PDF?",
            "description": "Example: 'Download now, Sign up for more tips, or Contact us for details.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "button",
            "action": "submit",
            "label": "Submit",
            "theme": "primary"
          }
        ]
      }
      
    }
    else if(propsB.type == "linkedin"){
      formJSON = {
        "components": [
          {
            "type": "textarea",
            "key": "postKeyMessage",
            "label": "Key Message or Insight",
            "placeholder": "What key message or insight should each post convey?",
            "description": "Example: 'Emphasize innovation and thought leadership in your industry.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "thoughtLeadershipPoints",
            "label": "Thought Leadership Points",
            "placeholder": "Are there specific thought leadership points to emphasize?",
            "description": "Example: 'Highlight your unique perspective and industry expertise.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "trendingIndustryTopics",
            "label": "Trending Industry Topics",
            "placeholder": "Any trending industry topics to reference?",
            "description": "Example: 'Include current trends like AI, sustainability, or digital transformation.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "engagementStyle",
            "label": "Preferred Engagement Style",
            "placeholder": "What’s the preferred engagement style (ask questions, share insights, provoke discussion)?",
            "description": "Example: 'Encourage audience interaction by asking questions or sharing insights that provoke discussion.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "achievementsStatsCaseStudies",
            "label": "Achievements, Stats, or Case Studies",
            "placeholder": "Should any specific achievements, stats, or case studies be included?",
            "description": "Example: 'Mention awards, performance stats, or notable case studies that build credibility.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "postCTA",
            "label": "Call-to-Action (CTA)",
            "placeholder": "What’s the CTA for each post (comment, share, visit website)?",
            "description": "Example: 'Encourage users to comment, share the post, or visit your website.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "button",
            "action": "submit",
            "label": "Submit",
            "theme": "primary"
          }
        ]
      }
      
    }
    else if(propsB.type == "brainstorming"){
      formJSON = {
        "components": [
          {
            "type": "textarea",
            "key": "currentProductsServices",
            "label": "Current Products/Services",
            "placeholder": "What current products/services are being offered?",
            "description": "Example: 'Online courses, coaching services, physical products, etc.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "customerFeedback",
            "label": "Customer Feedback & Requests",
            "placeholder": "What customer feedback or recurring requests have you received?",
            "description": "Example: 'Requests for more in-depth tutorials, additional product features, etc.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "offeringGaps",
            "label": "Gaps in Current Offerings",
            "placeholder": "Are there any gaps in your current offerings you’d like to fill?",
            "description": "Example: 'Identify areas where customer needs are not fully met.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "productPreference",
            "label": "Product Type Preference",
            "placeholder": "Do you prefer digital products, physical products, or services?",
            "description": "Example: 'Digital products like eBooks, online courses, physical goods, or in-person services.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "emergingTrends",
            "label": "Emerging Trends to Explore",
            "placeholder": "Are there any emerging trends you'd like to explore?",
            "description": "Example: 'Trends like subscription models, eco-friendly products, or AI-powered services.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "offerGoal",
            "label": "Goal for New Offers",
            "placeholder": "What’s the goal for these new offers (recurring revenue, high-ticket, entry-level)?",
            "description": "Example: 'Aim for recurring revenue streams, high-ticket sales, or entry-level products to attract new customers.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "button",
            "action": "submit",
            "label": "Submit",
            "theme": "primary"
          }
        ]
      }
      
    }
    else if(propsB.type == "emails"){
      formJSON = {
        "components": [
          {
            "type": "textarea",
            "key": "emailProductService",
            "label": "Product/Service to Promote",
            "placeholder": "What product/service are we promoting?",
            "description": "Enter the product or service you want to promote through the email sequence.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "emailSequenceGoal",
            "label": "Main Goal of the Email Sequence",
            "placeholder": "What’s the main goal of the email sequence (purchase, book a call, register)?",
            "description": "Specify the primary objective of the email sequence.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "emailObjectionsConcerns",
            "label": "Objections or Concerns",
            "placeholder": "What objections or concerns do prospects commonly have?",
            "description": "List any common objections or concerns that need to be addressed.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "limitedTimeOffers",
            "label": "Limited-Time Offers / Bonuses / Deadlines",
            "placeholder": "Are there any limited-time offers, bonuses, or deadlines?",
            "description": "Include details about any urgency elements to be highlighted in the emails.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "testimonialsCaseStudiesStats",
            "label": "Testimonials, Case Studies, or Stats",
            "placeholder": "Do you have verified testimonials, case studies, or stats to include?",
            "description": "Add any verified testimonials, case studies, or statistics to build credibility.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "preferredTone",
            "label": "Preferred Tone",
            "placeholder": "What’s the preferred tone (friendly, professional, persuasive)?",
            "description": "Specify the tone to be used in the email sequence.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "button",
            "action": "submit",
            "label": "Submit",
            "theme": "primary"
          }
        ]
      }
      
    }
    else if(propsB.type == "upsell"){
      formJSON = {
        "components": [
          {
            "type": "textarea",
            "key": "upsellProductService",
            "label": "Upsell Product/Service",
            "placeholder": "What is the upsell product/service?",
            "description": "Example: 'Premium Coaching Package', 'Extended Warranty', etc.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "primaryProductPurchased",
            "label": "Primary Product Purchased",
            "placeholder": "What’s the primary product the customer just purchased?",
            "description": "Example: 'Basic Membership Plan', 'Standard Software License', etc.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "upsellPricePoint",
            "label": "Upsell Price Point",
            "placeholder": "What’s the price point for the upsell?",
            "description": "Example: '$99.99', '$199.99', etc.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "upsellKeyBenefit",
            "label": "Key Benefit of the Upsell",
            "placeholder": "What’s the key benefit of the upsell (why should they upgrade)?",
            "description": "Example: 'Gain access to exclusive features or faster support.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "upsellObjections",
            "label": "Objections to Address",
            "placeholder": "Are there any objections you want to address?",
            "description": "Example: 'Concerns about additional cost or perceived value.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "upsellBonusesIncentives",
            "label": "Bonuses or Limited-Time Incentives",
            "placeholder": "Should any bonuses or limited-time incentives be included?",
            "description": "Example: 'Free bonus content if upgraded within 24 hours.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "button",
            "action": "submit",
            "label": "Submit",
            "theme": "primary"
          }
        ]
      }
      
    }
    else if(propsB.type == "youtube"){
      formJSON = {
        "components": [
          {
            "type": "textarea",
            "key": "videoTopic",
            "label": "Video Topic/Main Focus",
            "placeholder": "What’s the topic or main focus of the video?",
            "description": "Example: 'How to build an online business from scratch' or a similar focus.",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "videoKeyMessage",
            "label": "Key Message/Takeaway",
            "placeholder": "What’s the key message or takeaway for viewers?",
            "description": "Example: 'Learn the essential steps to succeed in e-commerce.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "videoPointsTipsStats",
            "label": "Points, Tips, or Stats",
            "placeholder": "Are there specific points, tips, or stats to include?",
            "description": "Example: 'Include top three actionable tips, statistics, or detailed insights.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "videoVisuals",
            "label": "Visuals / On-Screen Text",
            "placeholder": "What type of visuals or on-screen text should be used?",
            "description": "Example: 'Include dynamic transitions, overlay text for key points, or infographic elements.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "videoTrendingFormats",
            "label": "Trending Formats/Styles",
            "placeholder": "Should the script follow trending formats/styles?",
            "description": "Example: 'Adopt popular TikTok trends, cinematic styles, or modern editing techniques.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "textarea",
            "key": "videoCTA",
            "label": "Call-to-Action (CTA)",
            "placeholder": "What’s the CTA at the end (subscribe, visit website, buy now)?",
            "description": "Example: 'Encourage viewers to subscribe, visit your website, or make a purchase.'",
            "input": true,
            "validate": {
              "required": true
            }
          },
          {
            "type": "button",
            "action": "submit",
            "label": "Submit",
            "theme": "primary"
          }
        ]
      }
      
    }
    else{
      formJSON = {
        display: "form",
        components: [
          {
            label: "Product Name",
            placeholder: "Enter the product name",
            type: "textarea",
            key: "productName",
            input: true,
            tableView: true,
            validate: {
              required: true
            }
          },
          {
            label: "Product URL",
            placeholder: "Enter the product URL",
            type: "textarea",
            key: "productURL",
            input: true,
            validate: {
              required: false,
              pattern: "^https?://.*$",
              customMessage: "Please enter a valid URL starting with http or https."
            },
            tableView: true
          },
          {
            label: "Product Description",
            placeholder: "Enter the product description",
            type: "textarea",
            key: "productDescription",
            input: true,
            tableView: true,
            validate: {
              required: true
            }
          },
          {
            label: "Key Features",
            placeholder: "Enter the product's Key Features",
            type: "textarea",
            key: "productKeyFeatures",
            input: true,
            validate: {
              required: true
            }
          },
          {
            type: "button",
            label: propsB.button,  // Using the value passed in via propsB
            input: false,
            key: "submit",
            tableView: false
          }
        ]
      };
    }
      

    // Render the Form.io form inside the modal
    const renderForm = async () => {
        const formElement = document.getElementById('formio');
        if (formElement) {
            // Await the creation of the form
            const form = await Formio.createForm(formElement, formJSON);
            
            // Load saved data from localStorage, if available
            const savedData = JSON.parse(localStorage.getItem('formData'));
            if (savedData) {
                form.submission = { data: savedData }; // Pre-fill the form with saved data
            }

            // Save form data to localStorage on form submit
            form.on('submit', (submission) => {
                setSubmissionData(submission.data);
                saveDataToLocalStorage(submission.data); // Save data
                // navigate('/content'); // Navigate to another page after submission
                subData = submission.data;
                const props = getStoredData();
                const prompt = generatePrompt(props)
                navigate('/results', { state: prompt })
                
                closeModal();
            });

            // Optional: Save form data to localStorage on form change (autosave)
            form.on('change', (submission) => {
                saveDataToLocalStorage(submission.data); // Autosave form data
            });
        }
    };

    // Function to save form data to localStorage
    const saveDataToLocalStorage = (formData) => {
        localStorage.setItem('formData', JSON.stringify(formData));
    };

    return (
        <div>
            {/* Trigger Button to open the modal */}
            <button onClick={openModal} className="font-poppins text-[18px] font-bold text-white px-[10px]">
                {`${propsB.button} >`}
            </button>

            {/* Modal */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={renderForm} // Render the Form.io form after the modal opens
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Promotional Content Form"
            >
                <h2 className="text-[35px] font-[700] text-center mb-[30px] text-black">Enter The Details</h2>
                {/* Form.io form will be rendered here */}
                <div id="formio" />

                <button onClick={closeModal} className="px-4 py-2 mt-4 bg-red-500 text-white rounded hover:bg-red-700 popup-close-btn">
                    &#10006;
                </button>
            </Modal>
        </div>
    );
};

export default ProductPromoFormModal;
