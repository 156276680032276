import React, { useState, useEffect } from 'react';

const LoadingScreen = ({ apiCallDuration }) => {
  const [timeLeft, setTimeLeft] = useState(apiCallDuration); // Initialize the countdown with the provided API call duration
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1); // Decrease time left by 1 second
      }, 1000);

      return () => clearInterval(timer); // Cleanup the timer on component unmount
    } else {
      setLoadingComplete(true); // Once countdown reaches 0, set loading as complete
    }
  }, [timeLeft]);

  // Calculate the percentage of time left
  const progressPercentage = ((apiCallDuration - timeLeft) / apiCallDuration) * 100;

  return (
    <div className="flex items-center p-4 justify-center w-full h-[450px] bg-[#FBF5FF] rounded-[50px]">
      {!loadingComplete ? (
        <div className="text-center">
          <div className="mb-4 font-semibold md:text-[35px] text-[25px] text-black">
            Your request is being processed...
          </div>
          <div className="text-black text-[20px] font-[500]">
            Estimated time left: <span className='text-[#AAA]'>{timeLeft}s</span>
          </div>

          {/* Progress Bar */}
          <div className='flex mt-4 items-center'>
            <div className="w-full max-w-[460px] mx-auto h-4 bg-[#FFFFFF] rounded-full overflow-hidden ">
              <div
                className="h-full bg-[#8868F1]  rounded-[100px]"
                style={{ width: `${progressPercentage}%`, transition: 'width 1s linear' }}
              ></div>
            </div>
            <div className='text-[#344054] text-[24px] w-[100px] font-[700]'>{Math.round(progressPercentage)}%</div>
          </div>
        </div>
      ) : (
        <div className="text-center w-full">
          <div className="mb-4 font-semibold text-[30px] text-black">
            Processing complete!<br />Rendering the Results
          </div>
          {/* Final Progress Bar at 100% */}
          <div className='flex mt-4 justify-center items-center'>
            <div className="w-full max-w-[460px] h-4 bg-[#FFFFFF] rounded-full overflow-hidden">
              <div className="h-full bg-[#8868F1] rounded-[100px]" style={{ width: `100%` }}></div>
            </div>
            <div className='text-[#344054] text-[24px] w-[100px] font-[700]'>100%</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;

