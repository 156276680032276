import SignIn from "../compements/SignIn"

function Signin() {
  return (
    <>
      <SignIn/>
    </>
  )
}

export default Signin
