// src/App.js
import React from 'react';
import Content from './pages/Content.js';
import Form from './pages/Form.js';
import Results from './pages/Results.js';
import Settings from './pages/Settings.js';
import Home from './pages/Home.js';
import MainLayout from './layout/MainLayout';
import Signin from './pages/Signin.js';
import SignUp from './pages/SignUp.js';
import ProtectedRoute from './compements/ProtectedRoute.js';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import useSubscriptionCheck from './hooks/useSubscriptionCheck';

// Wrapper component to run the subscription check hook
function SubscriptionProtected({ children }) {
  // This hook will sign the user out if their subscription is cancelled.
  useSubscriptionCheck();
  return children;
}

export default function HomeApp() {
  return (
    <Router>
      <Routes>
        <Route exact path="/signin" element={<Signin />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route path="/" element={<MainLayout />}>
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute>
                <SubscriptionProtected>
                  <Home />
                </SubscriptionProtected>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/form"
            element={
              <ProtectedRoute>
                <SubscriptionProtected>
                  <Form />
                </SubscriptionProtected>
              </ProtectedRoute>
            }
          />
          <Route
            path="/content"
            element={
              <ProtectedRoute>
                <SubscriptionProtected>
                  <Content />
                </SubscriptionProtected>
              </ProtectedRoute>
            }
          />
          <Route
            path="/results"
            element={
              <ProtectedRoute>
                <SubscriptionProtected>
                  <Results />
                </SubscriptionProtected>
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <ProtectedRoute>
                <SubscriptionProtected>
                  <Settings />
                </SubscriptionProtected>
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}
