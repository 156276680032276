// src/hooks/useSubscriptionCheck.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../api'; // your axios instance

const useSubscriptionCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const token = localStorage.getItem('jwt');
        if (!token) return;

        // Fetch current user details
        const response = await api.get('/api/users/me', {
          headers: { Authorization: `Bearer ${token}` },
        });

        const user = response.data;
        const subscriptionStatus = user.subscriptionStatus;
        const trialEnd = user.trialEnd; // expected to be a date string

        // Check if trialEnd exists and is in the future.
        let trialActive = false;
        if (trialEnd) {
          const trialEndDate = new Date(trialEnd);
          const now = new Date();
          if (trialEndDate > now) {
            trialActive = true;
          }
        }

        // If a valid trial is active, skip subscription status check.
        if (trialActive) {
          return;
        }

        // If trial is not active (empty, null, or in the past), then check subscription status.
        if (
          subscriptionStatus !== 'trialing' &&
          subscriptionStatus !== 'paid' &&
          subscriptionStatus !== 'draft'
        ) {
          // Sign the user out.
          localStorage.removeItem('jwt');
          localStorage.removeItem('user');
          alert('Your subscription has been cancelled. Please subscribe again to continue using the app.');
          navigate('/signin');
        }
      } catch (err) {
        console.error('Error checking subscription status:', err);
        // Optionally handle errors (e.g., network issues).
      }
    };

    // Check immediately.
    checkSubscriptionStatus();

    // Check periodically (e.g., every 5 minutes).
    const intervalId = setInterval(checkSubscriptionStatus, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [navigate]);
};

export default useSubscriptionCheck;
