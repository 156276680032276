import React from 'react'
import mainHeader from "../media/Dashboard/MainHeader.svg"
import chart1 from "../media/Dashboard/Chart-1.svg"
import chart2 from "../media/Dashboard/Chart-2.svg"
import chart3 from "../media/Dashboard/Chart-3.svg"
import list from "../media/Dashboard/list.svg"
import MainHeaderMob from "../media/Dashboard/MainHeaderMob.png"
import placeHolder from "../media/person-placeholder.png"
import { Link } from 'react-router-dom';
function Home() {
  return (
    <div className='max-w-[1300px] mx-auto'>
      <div className='md:flex gap-6'>
        <div className='md:w-2/3'>
          <Link to="/form">
            <img src={mainHeader} className='hidden md:block h-auto w-full drop-shadow-[0px_5_10px_rgba(97,220,251,0.6)] md:drop-shadow-[25px_20px_30px_rgba(97,220,251,0.6)]'/>
            <img src={MainHeaderMob} className='md:hidden h-auto w-full drop-shadow-[0px_5_10px_rgba(97,220,251,0.6)] md:drop-shadow-[25px_20px_30px_rgba(97,220,251,0.6)]'/>
          </Link>
          <div className='md:flex gap-6 mt-6'>
            <div className='md:w-1/2'>
              <img src={chart1} className='h-auto w-full drop-shadow-[0_20px_82px_rgba(110,110,110,0.10)]'/>
            </div>
            <div className='md:w-1/2'>
              <img src={chart3} className='h-auto w-full mt-6 md:mt-0 drop-shadow-[0_20px_82px_rgba(110,110,110,0.10)]'/>
              <img src={chart2} className='h-auto w-full mt-6 drop-shadow-[0_20px_82px_rgba(110,110,110,0.10)]'/>
            </div>
          </div>
        </div>
        <div className='md:w-1/3 bg-[#FBF5FF] rounded-[50px] px-[15px] mt-6 md:mt-0 md:px-[25px] py-[35px] flex flex-col justify-between'>
          <div>
            <h2 className='text-[20px] font-[500]'>Profile</h2>
            <img src={placeHolder} alt="Profile" className="w-full h-auto max-w-[250px] mx-auto rounded-full drop-shadow-[0_20px_20px_rgba(241,134,186,0.6)] p-[10px] border-[#F186BA] border-r-[3px] border-b-[3px]"/>
          </div>
          <img src={list} className='h-auto w-full'/>
        </div>
      </div>
    </div>
  )
}

export default Home
